/* .container-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
} */

.a-play {
  text-decoration: none;
  display: inline-block;
  /* background-image: linear-gradient(
    to right,
    #6400a4,
    #5e07b5,
    #5411c6,
    #411bd8,
    #1225eb
  ); */
  border: 2px dotted white;
  color: aliceblue;
  border-radius: 50%;
  padding: 5px;
  position: relative;
}

.a-play::after {
  position: absolute;
  content: "";
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0px;
  opacity: 1;
  transform-origin: center;
  animation: anim 1600ms linear infinite;
}

.a-play::before {
  position: absolute;
  content: "";
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0px;
  opacity: 0.8;
  transform-origin: center;
  animation: anim 1600ms linear 800ms infinite;
}

@keyframes anim {
  from {
    margin: 0px;
    opacity: 1;
  }

  to {
    margin: -15px;
    opacity: 0;
  }
}

.span-play {
  color: #fff;
  font-family: sans-serif;
  font-weight: 600;
  margin-left: 20px;
}
