.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0px 200px 0px;
}

.privacy-container {
  /* position: relative; */
  /* padding: 64px 42px; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: center; */
  gap: 25px;
  padding: 40px 20rem 0px 20rem;
  width: 100%;
  margin: 0 auto;
}

.privacy-container ul {
  padding-left: 40px;
  line-height: 20px;
}

.privacy-container ol {
  padding-left: 40px;
  line-height: 20px;
}

.privacy-title-text {
  font-family: "Krona One", sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 10px;
}

.privacy-sub-title {
  font-family: "Krona One", sans-serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 50px;
}

.privacy-text-desc {
  /* text-align: center; */
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
}

@media (max-width: 768px) {
  .main-container {
    padding: 50px 0px 100px 0px;
  }

  .privacy-container {
    padding: 40px 0px;
    width: 85%;
    margin: 0 auto;
  }

  /* .privacy-title-text {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 0px;
  } */

  .privacy-sub-title {
    font-size: 20px;
    line-height: 30px;
  }

  .privacy-text-desc {
    font-size: 12px;
    line-height: 25px;
  }

  td {
    font-size: 12px !important;
    padding: 15px 10px !important;
  }
  th {
    font-size: 12px !important;
    padding: 15px 10px !important;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  .privacy-container {
    padding: 40px 10rem 0px 10rem;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .privacy-container {
    padding: 40px 15rem 0px 15rem;
  }
}

.ulCustom {
  list-style-type: disc !important;
}

.olCustom {
  list-style-type: decimal !important;
}
