.swal2-popup {
  background-color: #2c2c2c !important;
  color: white;
  min-width: 536px;
  font-family: Montserrat !important;
}

div:where(.swal2-container) button:where(.swal2-styled) {
  box-shadow: none !important;
}
