/* .container-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
} */

.myFont {
  font-family: "Lexend Deca" !important;
}

.a-play {
  text-decoration: none;
  display: inline-block;
  /* background-image: linear-gradient(
    to right,
    #6400a4,
    #5e07b5,
    #5411c6,
    #411bd8,
    #1225eb
  ); */
  border: 2px dotted white;
  color: aliceblue;
  border-radius: 50%;
  padding: 5px;
  position: relative;
}

.a-play::after {
  position: absolute;
  content: "";
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0px;
  opacity: 1;
  transform-origin: center;
  animation: anim 1600ms linear infinite;
}

.a-play::before {
  position: absolute;
  content: "";
  bottom: -7px;
  top: -7px;
  left: -7px;
  right: -7px;
  background-color: transparent;
  border-radius: 50%;
  border: 2px solid #fff;
  margin: 0px;
  opacity: 0.8;
  transform-origin: center;
  animation: anim 1600ms linear 800ms infinite;
}

@keyframes anim {
  from {
    margin: 0px;
    opacity: 1;
  }

  to {
    margin: -15px;
    opacity: 0;
  }
}

.span-play {
  color: #fff;
  font-family: sans-serif;
  font-weight: 600;
  margin-left: 20px;
}

.gen-hero-text {
  font-family: "Lexend Deca";
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.gen-hero-text-desc {
  font-family: "Lexend Deca";
  font-size: 16px;
  line-height: 26.24px;
  text-align: center;
  font-weight: 100;
  color: #ffffffa9;
}

/* #TheDigitalDivaOfNewEra */

.styled-text {
  width: 90%; /* Adjusted for responsiveness */
  max-width: 1304px;
  height: auto; /* Allow dynamic height */
  margin: 0 auto; /* Center horizontally */
  top: 2638px; /* Consider dynamic positioning if necessary */

  font-family: "Lexend Zetta", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4rem; /* Responsive font size */
  line-height: 1.5; /* Adjusted for readability */
  text-align: center;

  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.5) 0%,
    rgba(153, 153, 153, 0.5) 52.5%,
    rgba(255, 255, 255, 0.5) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
  .styled-text {
    /* font-size: 3vw; Scale font down */
    font-size: 3rem; /* Responsive font size */
  }
}

@media (max-width: 768px) {
  .styled-text {
    /* font-size: 2vw; */
    font-size: 2rem; /* Responsive font size */

    line-height: 1.4;
  }
}

@media (max-width: 480px) {
  .styled-text {
    /* font-size: 2vw; */
    font-size: 1rem; /* Responsive font size */

    line-height: 1.3;
    top: auto; /* Remove fixed top position */
  }
}
